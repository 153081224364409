body {
  font-family: sans-serif;
  margin: auto;
  overflow: hidden;
}

#container {
  position: relative;
  /* height: 90vh; */
}

#container canvas {
  position: absolute;
  width: 100%;
  height: 100%;
}
canvas {
  transition: opacity 200ms;
}
#overlayCanvas {
  z-index: 2;
}

#outputCanvas {
  z-index: 3;
}

#previewCanvas {
  z-index: 4;
  pointer-events: none;
}
.fade {
  opacity: 0.1;
}

button,
input {
  font-size: 1.5sem;
  border-radius: 0;
}
.selected {
  background: rgb(221, 201, 114);
}
#modes.flip button {
  background: rgb(221, 201, 114);
}
#modes.flip button.selected {
  background: #ddd;
}
div {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

#title {
  flex-grow: 1;
}
